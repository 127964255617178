import Vue from 'vue';
import {
  LiffListConfigInterface,
  TableDataType,
} from '@/components/list/types/list';
class listConfig {
  protected vm?: Vue;
  protected listKey?: string;

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm;
    this.listKey = listKey;

    return {
      pageTitle: 'module.product_unit',
      enable: {
        search: false,
      },
      displayMode: 'table',
      // displayModeTypes: ["table"],
      sort: [{ label: 'data.name', key: 'name' }],
      listItem: () => import('@/modules/product/components/productUnitListItem.vue'),
      table: {
        data: <TableDataType>[
          {
            key: 'name',
            label: 'data.name',
            type: 'text',
          },
        ],
      },
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({
            name: 'product-unit-update',
            params: { target: row.id },
          }),
          linkTarget: '_self',
          color:'#220AB4',
          buttonProperties: () => ({
            style: {
              borderRadius: '999px',
            }
          }),
        },
        delete: {
          removeTarget: row => row.name,
          buttonProperties: () => ({
            style: {
              borderRadius: '999px',
            }
          }),
        },
      },
    };
  }
}

export default new listConfig();
