<template>
  <div>
    <v-container class="d-flex justify-space-between align-center py-2">
      <page-title title="商品單位" />
      <v-btn
        small
        color="primary"
        :to="meta.create.route"
        :target="meta.create.linkTarget"
        class="mx-2"
        @click.native="onClick"
      >
        <span>{{ meta.create.label | t }}</span>
      </v-btn>
    </v-container>
    <liff-list :listKey="listKey">

    </liff-list>
  </div>
</template>

<script>
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './productUnitListConfig'

export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'product-unit-list',
    meta: {
      create: {
        label: 'action.create.product_unit',
        linkTarget: '_self',
        route: { name: 'product-unit-create' },
      }
    },
  }),
  computed: {
    providerId() {
      return this.$store.getters['member/providerId']
    },
  },
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      return await this.$api.collection.productUnitApi.index(this.providerId, params)
    },
    async deleteApi(target) {
      this.$root.gaLogEvent('供應商_點擊_刪除單位')
      return await this.$api.collection.productUnitApi.delete(this.providerId, {id: target})
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>